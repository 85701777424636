#drop{
    text-transform: capitalize;
    background-color: blueviolet ;
}
/* #addproject{
    
    margin:0% auto; 
} */
button{
   text-transform: capitalize;
}