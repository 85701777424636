.Navtext {
    /* background: transparent; */
  
    background-color: #022B69;

}

.navbar {
    box-shadow: 0px 0px 0px #fff !important;

}

.navfloatR {
    margin-left: 180px;
}


.nav-link {
    color: #ffffff;
    padding: 8px;
    text-transform: capitalize;
    width:auto!important;
}

.navbar-nav {
    /* margin-left: 50%; */
    box-sizing: border-box;
   
}
#brandname{
    color:#ffff!important;
    font-size: 2.2em; font-weight: bold!important; 
}
@media screen and (max-width:600px) {
    .navfloatR {
        margin-left: inherit;
    }
    #brandname{
    color:#ffff!important;
    font-size: 1.6em!important; font-weight: bold!important; 
}
}