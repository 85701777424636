@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800,900|Poppins:300,400,500,600,700,800,900&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto',
  sans-serif;
   background-color: '#f5f5f5';
  /* 'Roboto', sans-serif;  change roboto to popin and test it later*/
}
#root{
   background-color: '#f5f5f5';
}