




#h1 {
    color: #ffff;
    text-align: center;
    font-family: sans-serif;
    text-transform: capitalize;
    font-size: 18px;
    position: relative;
}

#h1:after {
    position: absolute;
    content: ".";
    -webkit-animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
    animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
}