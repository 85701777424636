#recentprojectlist{
   background-color: #ffffff;
}
#recentProjectTopic{
    box-shadow: 0px 0px 0px  white;
}
 #recentProjectTopic:hover{
    color: blue!important;
    cursor: pointer;
 
    
} 
#recentprojectlist:hover {
   background-color: #ffffff;
     box-shadow: 0.3px 0.3px 1px  blue;
     color: rgb(32, 31, 31);
    
    
}
