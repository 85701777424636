.App {
  text-align: left;
  /* font-family: 'cursive', sans-serif; F5F5F5*/
  
  background-color: rgb(241, 241, 241);
/* ATLBUNDHBB68751595060560375 */

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
.container{
  width: 100%!important;
  /* margin: 0px!important; */
  padding: 2px!important;

  /* background-color: red; */
}
@import url('https://fonts.googleapis.com/css?family=Poppins|Roboto&display=swap');

body {
  font-family: Poppins,
    sans-serif,
    Arial,
    Helvetica,
    sans-serif;
    color:black;
    background-color: '#f5f5f5';
    /* #1b1f5c; */
   
}



.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}