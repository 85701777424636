@media screen and (max-width:576px) {
      #menu {
        display: none;
        margin-top: 0px !important;
        /* position: absolute !important; */

    }

    /* .mobilehmenu {
        display: flex;

    } */
    #searchhidmobile{
        margin-top: 2px!important;
        
    }

    #mobiled{
margin-top: 200px;
    }
  
}