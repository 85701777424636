.navlink{
    width: 32%;
    height: 10%;
    /* border-radius: 80px; */
    background-color:#fff;
    /* background-image: url('../assets/img/project.jpeg'); */
    /* margin: 0%  */
    color: rgb(3, 10, 73);
    /* box-shadow: 0.5px 0.5px 10px grey; */
    text-transform: capitalize;
    text-align: center;
    
}
#sideBarHome{
display: none;
}
.itt :hover{

    box-shadow: 2px 2px 5px blue!important;
    background-color: #fff!important;
   
}
@media screen and (max-width:600px){
    .navlink{
        width:100%;
        height: 10%;
        border-radius: 0px;
        padding: 10px;
        margin-bottom: 20px;
    }

    #sideBarHome{
display: block;
}
#hidesideBarHome{
    display: none;
   
}
}
.dlink{
    background-color: #fff;
  
    width: 50%;
    height: auto;
    padding: 10%;
    color: rgb(3, 10, 73);
    ;

}
.dlink:hover{
 background-color: lightcoral;
}
.icon{
    font-size: 50px;
    cursor: pointer;
    color: rgb(3, 10, 73);
    
}
