.hmenu {
    background-color:#ffffff;
}
.mobilehmenu{
    display: none;
}
@media screen and (max-width:576px){
.hmenu{
    display: none;
    margin-top: 0px!important;
    /* position: absolute !important; */
    
}
.mobilehmenu{
    display: flex;
    
}

}