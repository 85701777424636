/* #Particles{
    /* display: none; */
    /* visibility: hidden; */

/* }  */
.prohead{
    font-weight: bold;
}

@media screen and (max-width:576px) {
    .share{
        font-size: 16px;
    }
    .sharep{
        padding: 10px 2px!important;
    }
    .sharenet{
        font-size: smaller;
        font-style: oblique;
    }
    .prohead{
        font-style: 12px!important;
        font-weight: normal;
    }
    .protext, .learnm {
        font-size: smaller;
    }
    #categoryhead{
        text-align: center!important;
    }
}