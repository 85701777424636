.sideSearchResult {
    display: none;
}
.resultt{
    margin-top:8%;
}


@media (max-width: 576px) {
    .sideSearchResult {
        display: block;
        color: black;


    }
    #resultt{
        margin-top:15%!important;
        color: black !important;
        
    }
 .ttt{
     margin-top: 500px!important;
 }

}