
/* .allprojects{
    margin:0px 20px;
    

} */

       .hr {
        box-shadow: 1px 1px 2px black ;
         margin-top:'0px';
    }
    .madd{
        display: none;
    }
    #toopicc{
     color: auto
      
    }
    #toopicc:hover{
        color:rgb(21, 33, 255)!important;
    }
@media screen and (max-width:576px){
    .adsearch{
        display: none;
        
    }
    .hr{
        margin-top:0px !important;

        display: none;
    }
    .madd {
        display: flex;
    }
    .mproject{
        margin-top: 0px!important;
    }
}