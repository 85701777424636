/* .App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
  }
   */
.h {
  background: #1b1f5c;
}

.App-title {
  font-size: 1.5em;
  z-index: 1;
  color: #fff;
}