.list-group-item {
  border: 0px;
  /* padding: 2px 0px */
}

/* .list-link{
     box-sizing: border-box;
    padding: 5px 8px;
         display: block;
         
} */
.position-fixed{
  
 
  /* width: 250px; */
  margin-right: 0px!important;
  
}
.list-link:hover {
  box-sizing: border-box;
  /* padding: 5px 8px; */
  display: block;
  background-color: #1976d2;
  color: white;
  border-radius: 20px;
}
@media (max-width: 576px) {
  #sidebar {
    display: none;
    position: relative !important;
  }

  #smalldevice {
    display: block!important;
    /* margin-left: -15px; */
   
  }
  
    
}
