.sideupprofile{
    display: none;
}

@media (max-width: 576px) {
    .sideupprofile {
        display: flex;
    }
    #hidesideBarHome{
    display: none;
   
}

}