.particle {
  z-index: -1;

  position: absolute;
  /* background-image: linear-gradient(to right, #341f97, #341f97); */
  /* background-attachment: fixed; #022B69*/
  background-image: linear-gradient(to right, #f2f2f2ff, #f2f2f2);
  width: 100%;
  height: 100%;
  top: -1;
  color: #1b1f5c;
  /* top:-1%; */
  /* position:absolute; */


  /* left:0%; */
  /* transform:rotate(2deg); */
  /* margin-bottom: 30px; */
  /* border-right: 20px red; */
  /* height: 400px; */
  /* margin: 0px 0px 0px 0px; */
  /* border-radius: 0px 0px 50% 50%;
    box-shadow: 5px 5px 50px 5px #8a8787;
     */


}

#particle {
  width: 0;
  height: 0;
  border-left: 500px solid transparent;
  border-right: 500px solid transparent;
  border-top: 0px solid red;
  color: #022B69;
}

.pSection {
  padding: 4%;
  color: #1b1f5c;
  text-align: left;

}
#landingregisterbtn{
  
   width:60%;
   font-family: "poppins";
   font-weight: bold;
   background-color: #1b1f5c;
   

   
}

.landingHead {
  height: 700px;
 
  overflow: hidden;
}
.caption{
  font-size: 3rem;
  font-weight: bold;
  text-align: left;
  line-height: 1.4em;

}
.captionword{
  margin-bottom: 0;
  padding-bottom: 0;
}
.mobile{
  display: none;
}
 .hidelogin {
   display: "block";
 }

@media screen and (max-width:700px) {
  .particle {
    /* background-image:linear-gradient(to right,#0c0e14, #194de7); */
    padding: auto;
    height: 350px;
    color: #022B69;
    display: none;

  }
.caption {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 8%;
  line-height:40px;

}
.captionword {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: medium;
  text-align: center;
}

.pSection {
  /* /* padding: 4%; */
  color: #1b1f5c;
  text-align: center; 

}
#landingregisterbtn{
  
    display: none;
}
#landingloginbtn{
   
  
    
    text-transform: capitalize;
}

.landingHead {
  height: auto;
}
  .pSection {
    padding: 0px;
    /* color:#fff; */



  }
  .mobile {
    display: block;
  }
  .hidelogin {
    display: none;
   
  }
}
@media (min-width:601px) and (max-width:993px) {
  .hidelogin {
    display: none;

  }

}